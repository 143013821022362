import axios from "axios";

class Reports {
  constructor(Service, path, Util) {
    this.httpService = Service;
    this.path = path;
    this.Util = Util;
  }

 getFuelTableApi = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + 'vehicles/fuel/getFuelV3';
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getFuelReport = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + "vehicles/fuel/report";
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
getDistanceSummary =(data) =>{
  const url =
  process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + `flespi/distanceSummeryReport?month=${data}`
return new Promise((resolve, reject) => {
  this.httpService
    .get(url)
    .then((res) => {
      resolve(res);
    })
    .catch((error) => {
      reject(error);
    });
});
}
searchDistanceSummary =(data) =>{
  // console.log(data)
  const url =
  process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + `flespi/distanceSummeryReport?month=${data.searchDate}&supervisorId=${data.supervisor}&truckId=${data.vehicles}`
return new Promise((resolve, reject) => {
  this.httpService
    .get(url)
    .then((res) => {
      resolve(res);
    })
    .catch((error) => {
      reject(error);
    });
});
}
getsupervisorList =() =>{
  // console.log(data)
  const url =
  process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + "authuser/1"
return new Promise((resolve, reject) => {
  this.httpService
    .get(url)
    .then((res) => {
      resolve(res);
    })
    .catch((error) => {
      reject(error);
    });
});
}
getUsersList =() =>{
  // console.log(data)
  const url =
  process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + "authuser"
return new Promise((resolve, reject) => {
  this.httpService
    .get(url)
    .then((res) => {
      resolve(res);
    })
    .catch((error) => {
      reject(error);
    });
});
}
vehicleslist = (data) =>{
  let filterUrl = data && data ? process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + `vehicles?allVehicles=true&marketVehicles=${data?.marketVehicles}&ownVehicles=${data?.ownVehicles}`:process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + `vehicles?allVehicles=true`;
  const url = filterUrl
  
return new Promise((resolve, reject) => {
  this.httpService
    .get(url)
    .then((res) => {
      resolve(res);
    })
    .catch((error) => {
      reject(error);
    });
});
}
getVehicleslistBysupId = (data) =>{
  const url =
  process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + `vehicles?allVehicles=true&marketVehicles=true&ownVehicles=true&supId=${data}`;
  return new Promise((resolve, reject) => {
  this.httpService
    .get(url)
    .then((res) => {
      resolve(res);
    })
    .catch((error) => {
      reject(error);
    });
});
}
getBreakdownReport = (data) =>{
  const url =
  process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + 'flespi/breakdownReport'
return new Promise((resolve, reject) => {
  this.httpService
    .post(url,data)
    .then((res) => {
      resolve(res);
    })
    .catch((error) => {
      reject(error);
    });
});
}

serachBreackdownReport = (data) => {
  const url = process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + `flespi/breakdownReport`
  return new Promise((resolve,reject) =>{
    this.httpService
    .post(url,data)
    .then((res) => {
      resolve(res);
    })
    .catch((error)=>{
      reject(error);
    });
  });
}

getEmptyTripSummary =(data) =>{
  const url =
  process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + `reports/emptyTripSummmary?fromDate=${data.fromDate}&toDate=${data.toDate}`
return new Promise((resolve, reject) => {
  this.httpService
    .get(url)
    .then((res) => {
      resolve(res);
    })
    .catch((error) => {
      reject(error);
    });
});
}
getProfitandLossReport =(data) =>{
  const url =
  process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + `trips/pandl/Statement?startDate=${data.startDate}&endDate=${data.endDate}`
return new Promise((resolve, reject) => {
  this.httpService
    .get(url)
    .then((res) => {
      resolve(res);
    })
    .catch((error) => {
      reject(error);
    });
});
}
getSpeedReport = (data) =>{
  const url = process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + "flespi/speedReport";
  return new Promise((resolve,reject) =>{
    this.httpService
    .post(url,data)
    .then((res) =>{
      resolve(res);
    })
    .catch((error) =>{
      reject(error);
    });
  });
}

getSpeedwiseReport = (data) =>{
  const url = process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + "flespi/speedWiseReport";
  return new Promise((resolve,reject) =>{
    this.httpService
    .post(url,data)
    .then((res)=>{
      resolve(res);
    })
    .catch((error)=>{
      reject(error);
    })
  })
}
getIgnitionReport = (data) =>{
  const url = process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + "flespi/ignitionReport";
  return new Promise((resolve,reject)=>{
    this.httpService
    .post(url,data)
    .then((res)=>{
      resolve(res)
    })
    .catch((error)=>{
      reject(error)
    })
  })
}
getDistanceReport = (data) =>{
  const url = process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + "flespi/distanceReport";
  return new Promise((resolve,reject) =>{
    this.httpService
    .post(url,data)
    .then((res) =>{
      resolve(res);
    })
    .catch((error) =>{
      reject(error);
    });
  });
}
getTrackHistoryReport = (data) =>{
  const url = process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + "flespi/trackHistoryReport";
  return new Promise((resolve,reject) =>{
    this.httpService
    .post(url,data)
    .then((res) =>{
      resolve(res);
    })
    .catch((error) =>{
      reject(error);
    });
  });
}
getHourlyPingReport = (data) =>{
  const url = process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + "flespi/hourlyPingReport";
  return new Promise((resolve,reject) =>{
    this.httpService
    .post(url,data)
    .then((res) =>{
      resolve(res);
    })
    .catch((error) =>{
      reject(error);
    });
  });
}
getTripsReport = (data) =>{
  const url =  process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + "vehicles/autoTripReport";
  return new Promise((resolve,reject) =>{
    this.httpService
    .post(url,data)
    .then((res) =>{
      resolve(res);
    })
    .catch((error) =>{
      reject(error);
    });
  });
}
vehicleslistByType = (data) => {
  const url =
   process.env.REACT_APP_SUVEGA_API_ENDPOINT_1+
    this.Util.queryParamsFromObj("vehicles", data);
  return new Promise((resolve, reject) => {
    this.httpService
      .get(url)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
// 
getDepartments = (data) => {
  const url = process.env.REACT_APP_SUVEGA_API_ENDPOINT_1+"reports/departments";
  return new Promise((resolve, reject) => {
    this.httpService
      .get(url)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
addDepartments = (data) =>{
  const url =  process.env.REACT_APP_SUVEGA_API_ENDPOINT_1+"reports/addDepartment";
  return new Promise((resolve,reject) =>{
    this.httpService
    .post(url,data)
    .then((res) =>{
      resolve(res);
    })
    .catch((error) =>{
      reject(error);
    });
  });
}
getVehicleDistanceReport = (data) =>{
  const url =  process.env.REACT_APP_SUVEGA_API_ENDPOINT_1+"reports/vehicleDistanceReport";
  return new Promise((resolve,reject) =>{
    this.httpService
    .post(url,data)
    .then((res) =>{
      resolve(res);
    })
    .catch((error) =>{
      reject(error);
    });
  });
}
getVehicleSummaryReport = (data) =>{
  const url =  process.env.REACT_APP_SUVEGA_API_ENDPOINT_1+"reports/vehicleSummaryReport";
  return new Promise((resolve,reject) =>{
    this.httpService
    .post(url,data)
    .then((res) =>{
      resolve(res);
    })
    .catch((error) =>{
      reject(error);
    });
  });
}
getPaymentReport = (data) =>{
  const url =  process.env.REACT_APP_SUVEGA_API_ENDPOINT_1+"driverPayments/paymentReport";
  return new Promise((resolve,reject) =>{
    this.httpService
    .post(url,data)
    .then((res) =>{
      resolve(res);
    })
    .catch((error) =>{
      reject(error);
    });
  });
}

getAllAuthUserRole =() =>{
  // console.log(data)
  const url =
  process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + "authuser"
return new Promise((resolve, reject) => {
  this.httpService
    .get(url)
    .then((res) => {
      resolve(res);
    })
    .catch((error) => {
      reject(error);
    });
});
}
};

export default Reports;
